import Constants from '../Constants';

class Calculator {
	static fuelPrice;
	static distancePerUnit;

	constructor(fuelPrice, distancePerUnit) {
        this.fuelPrice = fuelPrice;
        if (typeof fuelPrice === 'string') {
            this.fuelPrice = parseFloat(fuelPrice.replace(/,/g, '.'));
        }
		this.distancePerUnit = distancePerUnit;
	}

    getRDelta(labelA, labelB) {
    	/*var diff = Math.abs(labelA - labelB);
    	var labelMax = Math.max(labelA, labelB);
    	var rDelta = 0;
    	for (let i=0; i<diff; i++) {
    		rDelta += Constants.labelList[labelMax-i].rDelta;
    	}
    	return rDelta/100;*/
		console.log('labels', labelA, labelB);
        return Math.abs(Constants.labelList[labelA].aDelta - Constants.labelList[labelB].aDelta)/100;
    }
    getCostForLabelPer(unit, label) {
        var rDelta = this.getRDelta(0, label);
        var cost = (this.fuelPrice / this.distancePerUnit) * (1 + rDelta);
        if (unit === 'lifespan') {
            return Math.ceil(cost*Constants.lifespanDistance);
        } else {
            return Math.ceil(cost);
        }
    }
    getCostDifferencePer(unit, labelA, labelB) {
    	var costPerUnit = this.fuelPrice / this.distancePerUnit;
    	var cost = costPerUnit;
    	if (unit === 'lifespan') cost = cost*Constants.lifespanDistance;

    	if (typeof labelA === 'undefined') {
    		var costDifferencePerLabel = []
    		for(let i in Constants.labelList) {
    			var rDelta = this.getRDelta(0, i);
    			costDifferencePerLabel[i] = Math.ceil(rDelta*cost);
    		}
    		return costDifferencePerLabel;
    	}
    	return Math.ceil(this.getRDelta(labelA, labelB) * cost);
    }
};

export default Calculator;
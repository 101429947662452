import Constants from './Constants';
import React from 'react';
import cookie from 'react-cookies';
import CountUp from 'react-countup';

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.calculateSavings = this.calculateSavings.bind(this);
    this.onSelectedLabel = this.onSelectedLabel.bind(this);
    this.savings = React.createRef();
    this.state = {
    	savings: {},
    	prevSavings: 0,
    	labelA: cookie.load('labelA') || Constants.defaultLabels[0],
    	labelB: cookie.load('labelB') || Constants.defaultLabels[1]
    };
  }

  componentDidMount() {
    this.calculateSavings();
  }

  componentDidUpdate() {
    this.calculateSavings();
  }

  calculateSavings(labelChanged, itemId) {
  	var labelA = this.state.labelA;
  	var labelB = this.state.labelB;

  	if (labelChanged === 'labelA') {
  		labelA = itemId;
  	} else if (labelChanged === 'labelB') {
  		labelB = itemId;
  	}

    var savings = this.props.getSavings(labelA, labelB);
    if (savings.savings !== this.state.savings.savings || labelA !== this.state.labelA || labelB !== this.state.labelB) {
      this.setState((prevState) => ({
      	prevSavings: prevState.savings.savings,
      	savings: savings,
      	labelA: labelA,
      	labelB: labelB
      }), () => {
        // fit the cost text
        //let text = this.savings.current.querySelector('.text');
        //fitty(text);
      });
    }
  }


  onSelectedLabel(selectorKey, itemId) {
  	/*this.setState((prevState, props) => {
  		// TODO create CSS animateion here.
  		return {[selectorKey]: itemId};
  	})*/

  	cookie.save(selectorKey, itemId);
  	this.calculateSavings(selectorKey, itemId);
  }

  render() {
  return <React.Fragment>

      <div className="payoff">Vergelijk twee bandenlabels</div>
      <div className="comparison">
        <Labels selectorKey="labelA" cost={this.state.savings.costLabelA} selected={this.state.labelA} onSelectedLabel={this.onSelectedLabel} />
        <Labels selectorKey="labelB" cost={this.state.savings.costLabelB} selected={this.state.labelB} onSelectedLabel={this.onSelectedLabel} />

        <div className="result">
          <div className="savings" ref={this.savings}><div className="text">
            <span>€</span><CountUp duration={2} start={this.state.prevSavings} end={this.state.savings.savings ? this.state.savings.savings : 0} />
          </div></div>
          <div className="payoff">Brandstofbesparing<br /> op een set van 4 banden<br /> bij 50.000 km.</div>
        </div>


  	    <div className="details">Kijk voor meer info over banden en het bandenlabel op <a href="http://www.kiesdebesteband.nl" target="_blank" rel="noopener noreferrer"> kiesdebesteband.nl</a>.
        </div>
      </div>
    </React.Fragment>
  }
}


class Labels extends React.Component {

  constructor(props) {
    super(props);

    this.crosshair = React.createRef();
    this.container = React.createRef();

    this.moveCrossHair = this.moveCrossHair.bind(this);

    //const Labels = ({ selected, selectorKey, onSelectedLabel, cost }) => {
  }

  componentDidMount() {
    this.moveCrossHair();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selected !== this.props.selected) {
      this.moveCrossHair(prevProps.selected);
    }
  }

  moveCrossHair(from) {
    let el = this.crosshair.current;
    let property = 'transform';

    if (from) {
      let pos = 100 / 1.2 * from;
      let value = 'translate('+pos+'%, 0)';

      el.style[property] = value;
      el.style["webkit" + property] = value;
      el.style["moz" + property] = value;
      el.style["ms" + property] = value;
      el.style["o" + property] = value;

    }
    var that = this;
    setTimeout(function(){
      let pos = 100 / 1.2 * that.props.selected;
      let value = 'translate('+pos+'%, 0)';

      el.style[property] = value;
      el.style["webkit" + property] = value;
      el.style["moz" + property] = value;
      el.style["ms" + property] = value;
      el.style["o" + property] = value;
    },0);
  }

  render() {
	return <div ref={this.container} className={"labelContainer " + this.props.selectorKey} key={this.props.selectorKey} data-selected={this.props.selected}>
      <div className="crosshair" ref={this.crosshair}></div>
    	<ul className="selector" id={"selector-" + this.props.selectorKey} data-selected={this.props.selected}>
    		{Constants.labelList.map((item, index) => (
    		<SelectorItem key={item.id} selectorKey={this.props.selectorKey} item={item} onSelectedLabel={this.props.onSelectedLabel} selected={this.props.selected} />
    		))}
    	</ul>
    </div>
  }
}

const SelectorItem = ({ selectorKey, item, onSelectedLabel, selected }) => {
	const _onClick = () => {
		onSelectedLabel(selectorKey, item.id);
		//document.getElementById("selector-" + selectorKey).classList.toggle('hide');
	}

	return <li data-id={item.id} className={selected === item.id ? 'active' : ''} onClick={_onClick}>
		<div className="label">{item.name}</div>
	</li>
}



export default Results;
const Constants = {
  distanceUnit: 'km',
  distancePerUnit: 18,
  distancePerYear: 15000,
  lifespanDistance: 50000,
  labelList: [
    {id: 0, name: 'A', 'rDelta': 0, 'aDelta': 0},
    {id: 1, name: 'B', 'rDelta': 1.61, 'aDelta': 1.61},
    {id: 2, name: 'C', 'rDelta': 1.74, 'aDelta': 3.35},
    {id: 3, name: 'D', 'rDelta': 2.01, 'aDelta': 5.36},
    {id: 4, name: 'E', 'rDelta': 2.14, 'aDelta': 7.50}
  ],
  fuelList: [
    { id: 0, name: 'Benzine', price: 1.73, per: 'Brandstofverbruik 1 op', unit: 'Brandstofprijs (L)', distance: 18},
    {id: 1, name: 'Diesel', price: 1.50, per: 'Brandstofverbruik 1 op', unit: 'Brandstofprijs (L)', distance: 18},
    { id: 2, name: 'LPG', price: 0.93, per: 'Brandstofverbruik 1 op', unit: 'Brandstofprijs (L)', distance: 18},
    {id: 3, name: 'Elektrisch', price: 0.25, per: 'Verbruik kWh/100 km.', unit: 'Energieprijs (kWh)', distance: 16}
  ],
  defaultLabels: [0,3]
}

export default Constants
import React from 'react';

const FuelList = ({ selected, onSelectFuel, fuelList }) => {
  return <ul className="fuelSelect">
  {fuelList.map(item => {
    return <Item selected={selected === item.id} key={item.id}  item={item} onSelectFuel={onSelectFuel}/>
  })}
  </ul>
};

const Item = ({ selected, item, onSelectFuel }) => {
  const _onClick = () => {
    onSelectFuel(item);
  }
  return <li data-id={item.id} className={selected ? "selected": ""} onClick={_onClick}>
    <div className="label">{item.name}</div>
   </li>
}

export default FuelList;